import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { match } from 'ts-pattern';

import { type DtoOneTimePurchaseActivity } from '@lp-lib/api-service-client/public';

import { GamePackCover } from '../components/Game/Utilities';
import { getFeatureQueryParamString } from '../hooks/useFeatureQueryParam';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { fromDTOGamePack } from '../utils/api-dto';
import { formatCurrency } from '../utils/currency';
import { tokenWithRedirect } from '../utils/router';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const resp = await tokenWithRedirect(
    () => apiService.otp.listActivities(),
    action.request.url,
    { admin: true }
  );
  return resp.data;
};

function formatTime(
  time: Nullable<string>,
  fallback = 'Not Yet',
  className = '',
  tz = getFeatureQueryParamString('toolkit-tz')
) {
  if (!time) return fallback;
  const zoneTime = utcToZonedTime(time, tz);
  return <div className={className}>{format(zoneTime, 'MM/dd/yy HH:mm')}</div>;
}

function DomainClickableEmail(props: { email: string }) {
  const parts = props.email.split('@');

  if (parts.length < 2) return <span>{props.email}</span>;

  return (
    <div className='flex items-center'>
      <span>{parts[0]}@</span>
      <a
        href={`https://${parts[1]}`}
        className='text-primary underline font-medium'
        target='_blank'
        rel='noreferrer'
      >
        {parts[1]}
      </a>
    </div>
  );
}

function PriceInfo(props: { activity: DtoOneTimePurchaseActivity }) {
  const { activity } = props;
  const prices = [];
  if (activity.gamePack.extraSettings?.syntheticOTPPricingTable) {
    prices.push(...activity.gamePack.extraSettings.syntheticOTPPricingTable);
  }
  if (activity.gamePack.extraSettings?.oneTimePurchasePricingTable) {
    prices.push(...activity.gamePack.extraSettings.oneTimePurchasePricingTable);
  }
  const price = prices.find((p) => p.id === activity.priceId);
  if (!price) return <span>N/A</span>;
  return (
    <div>
      {price.maxPlayers} Players
      <br /> {formatCurrency(price.amount)}
    </div>
  );
}

function OTPActivities() {
  const { activities } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();

  return (
    <div className='relative w-full text-white flex flex-col gap-10'>
      <header className='flex justify-between items-center pb-4'>
        <h1 className='text-3.5xl font-bold'>One Time Purchase Activities</h1>
      </header>
      <table
        style={{
          borderCollapse: 'separate',
          borderSpacing: '0 10px',
        }}
      >
        <thead>
          <tr>
            <th className='text-left'>Organization</th>
            <th className='text-left'>Purchased By</th>
            <th className='text-left'>Game Pack</th>
            <th className='text-left'>Purchased At</th>
            <th className='text-left'>Last Attempt</th>
            <th className='text-left'>Price</th>
            <th className='text-left'>Paid With</th>
            <th className='text-left'>Status</th>
            <th className='text-left'>UTM Params</th>
            <th className='text-left'>Channel</th>
          </tr>
        </thead>
        <tbody className='text-sms'>
          {activities.map((activity, index) => (
            <tr key={index}>
              <td>{activity.organizer.organization?.name ?? 'N/A'}</td>
              <td>
                <DomainClickableEmail email={activity.organizer.email} />
              </td>
              <td
                className='cursor-pointer underline'
                onClick={() =>
                  navigate(`/admin/gamepacks?packId=${activity.gamePack.id}`)
                }
              >
                <div className='w-24'>
                  <GamePackCover pack={fromDTOGamePack(activity.gamePack)} />
                </div>
              </td>
              <td>
                {formatTime(
                  activity.purchasedAt,
                  'Incomplete',
                  'text-tertiary'
                )}
              </td>
              <td>{formatTime(activity.lastAttemptedAt)}</td>
              <td>
                <PriceInfo activity={activity} />
              </td>
              <td>
                {activity.stripeTxnType
                  ? activity.stripeTxnType === 'invoice'
                    ? 'Invoice'
                    : 'Card'
                  : 'N/A'}
              </td>
              <td>
                {match(activity)
                  .when(
                    () => !!activity.eventId,
                    () => (
                      <span
                        className='text-green-001 cursor-pointer underline'
                        onClick={() => navigate(`/events/${activity.eventId}`)}
                      >
                        Scheduled
                      </span>
                    )
                  )
                  .otherwise(() => (
                    <span>Not Scheduled</span>
                  ))}
              </td>
              <td>
                <div>Campaign: {activity.utm?.campaign || 'N/A'}</div>
                <div>Source: {activity.utm?.source || 'N/A'}</div>
                <div>Medium: {activity.utm?.medium || 'N/A'}</div>
              </td>
              <td>{activity.channel}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function Component() {
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />

      <OTPActivities />
    </AdminView>
  );
}
